import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import { useEffect, useContext } from 'react';
import ABeagleContext from '../../contexts/abeagle';

export function useAwin(buzz, AdsContext) {
  const { experiments, getFeatureFlagValue } = useContext(ABeagleContext);

  useEffect(() => {
    if (AdsContext && AdsContext.eventBus && experiments && experiments.loaded) {
      // temporary disable AWIN because it causes issue with non-Amazon affiliate links
      // by adding www. to all links
      if(!getFeatureFlagValue('RT-1510-ButtonPostTap-Non-Amazon')) {
        AdsContext.eventBus.on('ads:initialized', () => {
          loadScript('https://www.dwin2.com/pub.304459.min.js');
        });
      }
    }
  }, [AdsContext, buzz, experiments, getFeatureFlagValue]);

  return null;
}
